import React, { FC } from 'react';
import { SVGCIconProps } from '../SVGCIcon';

const Star: FC<SVGCIconProps> = (props) => {
    const { color = '#ACACAC', height = 24, width = 24, style } = props;
    return (
        <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_32607_11640)">
                <path d="M17.5716 3.07506C17.8824 2.57886 18.3142 2.16978 18.8265 1.8862C19.3387 1.60263 19.9147 1.45386 20.5002 1.45386C21.0857 1.45386 21.6616 1.60263 22.1738 1.8862C22.6861 2.16978 23.1179 2.57886 23.4287 3.07506L29.1102 12.0658L36.5195 12.9736C37.134 13.0423 37.7181 13.2774 38.2088 13.6535C38.6996 14.0296 39.0784 14.5326 39.3044 15.1081C39.5303 15.6836 39.595 16.31 39.4913 16.9195C39.3876 17.5291 39.1195 18.0988 38.7159 18.5672L32.5366 25.6543L34.528 35.4651C34.6528 36.1052 34.5924 36.7676 34.3538 37.3746C34.1151 37.9815 33.7082 38.5078 33.1809 38.8915C32.6469 39.2682 32.0178 39.4872 31.3653 39.5234C30.7128 39.5596 30.0633 39.4117 29.4909 39.0965L20.5002 34.1765L11.5095 39.0965C10.937 39.4117 10.2875 39.5596 9.63504 39.5234C8.98257 39.4872 8.35343 39.2682 7.81945 38.8915C7.2921 38.5078 6.8852 37.9815 6.64657 37.3746C6.40794 36.7676 6.34749 36.1052 6.47231 35.4651L8.46374 25.6543L2.28445 18.5672C1.88085 18.0988 1.61276 17.5291 1.50906 16.9195C1.40536 16.31 1.46999 15.6836 1.69598 15.1081C1.92198 14.5326 2.30077 14.0296 2.79153 13.6535C3.28228 13.2774 3.86639 13.0423 4.48088 12.9736L11.8902 12.0658L17.5716 3.07506Z" stroke="#000001" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_32607_11640">
                    <rect width="41" height="41" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Star;
