import React, { FC } from 'react';
import { SVGCIconProps } from '../SVGCIcon';

const Users: FC<SVGCIconProps> = (props) => {
    const { color = '#ACACAC', height = 24, width = 24, style } = props;
    return (
        <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_32607_11643)">
                <path d="M13.214 15.8572C16.4981 15.8572 19.1604 13.1948 19.1604 9.91072C19.1604 6.6266 16.4981 3.96429 13.214 3.96429C9.92988 3.96429 7.26758 6.6266 7.26758 9.91072C7.26758 13.1948 9.92988 15.8572 13.214 15.8572Z" stroke="#000001" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M25.107 35.6786H1.32129V33.0357C1.32129 29.8816 2.57428 26.8566 4.80463 24.6262C7.03497 22.3959 10.06 21.1429 13.2141 21.1429C16.3683 21.1429 19.3933 22.3959 21.6237 24.6262C23.854 26.8566 25.107 29.8816 25.107 33.0357V35.6786Z" stroke="#000001" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M23.7856 3.96429C25.3627 3.96429 26.8752 4.59079 27.9904 5.70596C29.1056 6.82113 29.7321 8.33363 29.7321 9.91072C29.7321 11.4878 29.1056 13.0003 27.9904 14.1155C26.8752 15.2307 25.3627 15.8572 23.7856 15.8572" stroke="#000001" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M28.0142 21.645C30.2654 22.5014 32.2036 24.0212 33.5722 26.0033C34.9408 27.9854 35.6753 30.3364 35.6784 32.745V35.6786H31.7142" stroke="#000001" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_32607_11643">
                    <rect width="37" height="37" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Users;
