import React, { FC } from 'react';
// eslint-disable-next-line import/no-cycle
import IconMap from './icons-mapping';
import { Theme, useTheme } from '@material-ui/core';

/**
 * Incomplete - Think more about it
 */
export interface IconProps {
    color?: 'primary' | 'secondary' | 'default' | 'contrast';
    width?: number;
    height?: number;
    styleClass?: Array<string> | string;
    iconProps?: Record<string, any>;
    style?: React.CSSProperties;
    htmlColor?: string;
    opacity?: number;
}

export interface SVGCIconProps extends IconProps {
    icon: TSVGCIcon;
}

const StyleColor = (theme: Theme): Record<string, string> => ({
    primary: theme.palette.primary.main,
    secondary: theme.palette.secondary.main,
    contrast: theme.palette.common.white,
});

const SVGCIcon: FC<SVGCIconProps> = (props) => {
    const { icon, color, htmlColor, ...iconProps } = props;

    const theme = useTheme();
    const iconColor = StyleColor(theme)[color ?? ''] || color;

    const Comp = IconMap[icon];
    return <Comp color={htmlColor ?? iconColor} {...iconProps} />;
};

export type TSVGCIcon =
    | 'icon-youtube'
    | 'icon-twitch'
    | 'icon-vimeo'
    | 'icon-clubhouse'
    | 'icon-zoom'
    | 'icon-remove'
    | 'icon-klaviyo'
    | 'icon-sandbox'
    | 'icon-spatial'
    | 'icon-nowhere'
    | 'icon-ronday'
    | 'icon-file-upload'
    | 'icon-tiktok'
    | 'icon-bell'
    | 'icon-cube'
    | 'icon-wallet'
    | 'icon-discord'
    | 'icon-chat'
    | 'icon-album'
    | 'icon-soundtrack'
    | 'icon-twitter'
    | 'icon-key'
    | 'icon-stream'
    | 'icon-mic'
    | 'icon-mic-outlined'
    | 'icon-hash'
    | 'icon-settings'
    | 'icon-add-user'
    | 'icon-menu'
    | 'icon-points'
    | 'icon-info'
    | 'icon-lock'
    | 'icon-share'
    | 'icon-dollar'
    | 'icon-star'
    | 'icon-onboarding'
    | "icon-webserver"
    | "icon-safety"
    | "icon-users";


export default SVGCIcon;
