import React, { FC } from 'react';
import { SVGCIconProps } from '../SVGCIcon';

export interface BellProps extends SVGCIconProps {
    iconProps?: {
        color?: string;
        outlineColor?: string;
    };
}

const Dollar: FC<BellProps> = (props) => {
    return (
        <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_32607_11633)">
                <path d="M20.5 13.1786V8.78571" stroke="#000001" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M16.1074 24.8929C16.1074 27.0893 18.0696 27.8214 20.5003 27.8214C22.931 27.8214 24.8931 27.8214 24.8931 24.8929C24.8931 20.5 16.1074 20.5 16.1074 16.1072C16.1074 13.1786 18.0696 13.1786 20.5003 13.1786C22.931 13.1786 24.8931 14.2914 24.8931 16.1072" stroke="#000001" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M20.5005 27.8215V32.2143" stroke="#000001" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M20.5001 39.5357C31.0132 39.5357 39.5358 31.0131 39.5358 20.5C39.5358 9.98687 31.0132 1.46429 20.5001 1.46429C9.98693 1.46429 1.46436 9.98687 1.46436 20.5C1.46436 31.0131 9.98693 39.5357 20.5001 39.5357Z" stroke="#000001" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_32607_11633">
                    <rect width="41" height="41" fill="white" />
                </clipPath>
            </defs>
        </svg>

    );
};

export default Dollar;
