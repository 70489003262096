import React, { FC } from 'react';
import { SVGCIconProps } from '../SVGCIcon';

const Webserver: FC<SVGCIconProps> = (props) => {
    const { color = '#ACACAC', height = 24, width = 24, style } = props;
    return (
        <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_32607_11654)">
                <path d="M18.4999 35.6786C27.9873 35.6786 35.6784 27.9874 35.6784 18.5C35.6784 9.01252 27.9873 1.32141 18.4999 1.32141C9.0124 1.32141 1.32129 9.01252 1.32129 18.5C1.32129 27.9874 9.0124 35.6786 18.4999 35.6786Z" stroke="#000001" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M1.32129 18.5H35.6784" stroke="#000001" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M25.1069 18.5C24.7823 24.782 22.4684 30.7981 18.4997 35.6786C14.531 30.7981 12.2171 24.782 11.8926 18.5C12.2171 12.2179 14.531 6.20185 18.4997 1.32141C22.4684 6.20185 24.7823 12.2179 25.1069 18.5V18.5Z" stroke="#000001" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_32607_11654">
                    <rect width="37" height="37" fill="white" />
                </clipPath>
            </defs>
        </svg>


    );
};

export default Webserver;
