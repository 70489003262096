import React, { FC, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { LinearProgress } from '@material-ui/core';
import PrivateRoute from 'Features/Auth/PrivateRoute';
import { TContentType } from 'Models/Drop/@types';
import lazyImport from '../lazy-import';

import Page404 from '../Screens/Page404';
import TestComponents from 'Screens/TestComponents';
import helpers from 'Utils/helpers';
const Login = lazyImport('../Screens/Login');
const Landing = lazyImport('../Screens/LandingScreen');
const EventPage = lazyImport('../Screens/EventPage');
const ResourcesPage = lazyImport('../Screens/ResourcesPage');
const DashboardHome = lazyImport('../Screens/Dashboard/DashboardHome');
const Drops = lazyImport('../Screens/Drops');
const CommunityDetail = lazyImport('../Screens/CommunityDetail');
const UserDetail = lazyImport('../Screens/UserDetails');
const CommunityScreen = lazyImport('../Screens/CommunityScreen');
const AboutUs = lazyImport('../Screens/AboutUs');
// const CreatorPage = lazyImport('../Screens/CreatorPage');
const Contact = lazyImport('../Screens/Contact');
const Privacy = lazyImport('../Screens/Privacy');
const NotificationsScreen = lazyImport('../Screens/Notifications');
const AccountNavigation = lazyImport('../Routes/AccountNavigation');
const SearchNavigation = lazyImport('../Routes/SearchNavigation');
// const CreateCommunity = lazyImport('../Screens/CreateCommunity');
const CommunityFormScreen = lazyImport('../Screens/CommunityFormScreen');
const Awards = lazyImport('../Screens/Awards');
const AwardType = lazyImport('../Screens/AwardType');
const Favorites = lazyImport('../Screens/Favorites');
const ThemeNavigation = lazyImport('../Routes/ThemeNavigation');
const ReportPage = lazyImport('../Screens/Report');
const IconReference = lazyImport('../Screens/IconReference');
const FollowUserScreen = lazyImport('../Screens/FollowUserScreen');
const ActivateCommScreen = lazyImport('../Screens/MarketingPages/ActivateCommScreen');
// const CreatorScreen = lazyImport('../Screens/MarketingPages/CreatorScreen');
const EventScreen = lazyImport('../Screens/MarketingPages/EventScreen');
const CreatorScreen = lazyImport('../Screens/MarketingPages/CreatorScreen');
const AmbassadorScreen = lazyImport('../Screens/MarketingPages/AmbassadorScreen');
const PricingScreen = lazyImport('../Screens/PricingScreen');
const DropsScreen = lazyImport('../Screens/DropsScreen');
const DiscoverScreen = lazyImport('../Screens/DiscoverScreen');
const RewardsScreen = lazyImport('../Screens/Rewards');

const AppNavigation: FC = () => (
    <>
        <Suspense fallback={null}>
            <Switch>
                {/** Static pages */}
                {/* <Route path={'/zoom-join'} component={ZoomClient} /> */}
                <Route path={APP_ROUTES.AMBASSADOR.path} component={AmbassadorScreen} />
                <Route path={APP_ROUTES.PRICING.path} component={PricingScreen} />
                {/* <Route path={APP_ROUTES.ABOUT_US.path} component={AboutUs} /> */}
                <Route path={APP_ROUTES.CREATOR.oldPath} render={() => <Redirect to={APP_ROUTES.CREATOR.path} />} />
                <Route path={APP_ROUTES.CREATOR.path} component={CreatorScreen} />
                <Route path={APP_ROUTES.CONTACT.path} exact component={Contact} />
                <Route path={APP_ROUTES.PRIVACY.path} exact component={Privacy} />
                <Route path={APP_ROUTES.AWARDS.path} exact component={Awards} />
                <Route path={APP_ROUTES.AWARD_TYPES.path} exact component={AwardType} />
                {helpers.isProduction() ? null : <Route path={APP_ROUTES.ICON_REF.path} exact component={IconReference} />}
                <Route path={APP_ROUTES.BRANDS.path} exact component={ActivateCommScreen} />

                {/** Dynamic pages */}
                <PrivateRoute path={APP_ROUTES.ACCOUNT.path} component={AccountNavigation} />
                <PrivateRoute path={APP_ROUTES.NOTIFICATIONS.path} component={NotificationsScreen} />
                <Route path={APP_ROUTES.HOME_PAGE.path} exact component={Landing} />

                {/** Auth Routes */}
                <Route path={APP_ROUTES.LOGIN.path} exact component={Landing} />
                <Route path={APP_ROUTES.SIGN_UP.path} exact component={Landing} />
                <Route path={APP_ROUTES.WALLET_AUTH.path} exact component={Landing} />

                <Route path={APP_ROUTES.SEARCH.path} component={SearchNavigation} />
                <Route path={APP_ROUTES.LOGIN.path} exact component={Login} />
                <Route path={APP_ROUTES.EVENT_PAGE.path} component={EventPage} />
                <Route path={APP_ROUTES.NEW_EVENT_PAGE.path} component={EventScreen} />
                <Route path={APP_ROUTES.VIDEO_DETAIL_PAGE.path} component={ResourcesPage} />
                <Route path={APP_ROUTES.ARTICLE_DETAIL_PAGE.path} component={ResourcesPage} />
                <Route path={APP_ROUTES.PODCAST_DETAIL_PAGE.path} component={ResourcesPage} />
                <Route path={APP_ROUTES.MERCH_DETAIL_PAGE.path} component={ResourcesPage} />
                <Route path={APP_ROUTES.ALBUM_DETAIL_PAGE.path} component={ResourcesPage} />
                <Route path={APP_ROUTES.SOUNDTRACK_DETAIL_PAGE.path} component={ResourcesPage} />
                <Route path={APP_ROUTES.DROPS.path} component={DropsScreen} />
                <Route path={APP_ROUTES.ALL_COMMUNITIES.path} component={CommunityScreen} />
                <Route path={APP_ROUTES.COMMUNITIES.oldPath} render={() => <Redirect to={APP_ROUTES.COMMUNITIES.path} />} />
                <Route path={APP_ROUTES.COMMUNITIES.path} component={DiscoverScreen} />
                <Route path={APP_ROUTES.USER_FOLLOW.path} component={FollowUserScreen} />
                <Route path={APP_ROUTES.USER_DETAIL.path} component={UserDetail} />
                <Route path={APP_ROUTES.FAVORITES.path} component={Favorites} />
                <Route path={APP_ROUTES?.REWARDS.path} component={RewardsScreen} />

                <PrivateRoute path={APP_ROUTES.REPORT.path} component={ReportPage} />
                <Route path={APP_ROUTES.THEMES.path} component={ThemeNavigation} />

                {!helpers.isProduction() ? <Route path={APP_ROUTES.TEST_COMPONENTS.path} component={TestComponents} /> : null}

                {/** Community path should be at the end because of its generalist slug rule */}
                {/* <PrivateRoute path={APP_ROUTES.CREATE_COMMUNITY.path} exact component={CreateCommunity} /> */}
                <PrivateRoute path={APP_ROUTES.CREATE_COMMUNITY.path} exact component={CommunityFormScreen} />
                <PrivateRoute path={APP_ROUTES.SUPER_ADMIN.path} component={DashboardHome} />
                <Route path={APP_ROUTES.COMMUNITY_DETAIL.path} component={CommunityDetail} />
                {/* <PrivateRoute path="/*" exact component={Page404} /> */}
            </Switch>
        </Suspense>
    </>
);

export enum AuthRoutes {
    LOGIN = 'login',
    SIGN_UP = 'sign-up',
    WALLET = 'wallet',
}

export const SINGLE_VIDEO_PATH = '/videos/:id';
export const SINGLE_PODCAST_PATH = '/podcast/:id';
export const SINGLE_ARTICLE_PATH = '/article/:id';
export const SINGLE_MERCH_PATH = '/merch/:id';
export const SINGLE_ALBUM_PATH = '/album/:id';
export const SINGLE_SOUNDTRACK_PATH = '/soundtrack/:id';

const DISCOVER_PAGE_ROUTE = '/communities';

export const APP_ROUTES = {
    ICON_REF: {
        path: '/icons',
    },
    HOME_PAGE: {
        path: '/',
    },
    NEW_EVENT_PAGE: {
        path: '/community-and-events',
    },

    EVENT_PAGE: {
        path: '/events/:id',
    },
    EVENT_PAGE_CONVERSATIONS: {
        path: '/events/:id/conversations',
    },
    DASHBOARD_HOME: {
        path: '/:communityId/dashboard',
    },
    SUPER_ADMIN: {
        path: '/super/dashboard',
    },
    NOTIFICATIONS: {
        path: '/notifications',
    },
    DROPS: {
        path: '/drops',
    },
    COMMUNITIES: {
        path: DISCOVER_PAGE_ROUTE,
        oldPath: '/discover',
    },
    ALL_COMMUNITIES: { path: `${DISCOVER_PAGE_ROUTE}/all` },
    USER_DETAIL: {
        path: '/user/:id',
    },
    USER_FOLLOW: {
        path: '/user/:id/follow',
    },
    COMMUNITY_DETAIL: {
        path: '/:communityId',
    },
    COMMUNITY_CONVERSATIONS: {
        path: '/:communityId/conversations',
    },
    CREATE_COMMUNITY: {
        path: '/community/new',
    },
    ABOUT_US: {
        path: '/about-us',
    },
    CREATOR: {
        path: '/creator',
        oldPath: '/build',
    },
    CONTACT: {
        path: '/contact',
    },
    PRIVACY: {
        path: '/privacy',
    },
    ACCOUNT: {
        path: '/account',
    },
    SEARCH: {
        path: '/search',
    },
    AWARDS: {
        path: '/awards',
    },
    AWARD_TYPES: {
        path: '/awards/:type',
    },
    FAVORITES: {
        path: '/:slug/favorite',
    },
    THEMES: {
        path: '/theme',
    },
    VIDEO_DETAIL_PAGE: {
        path: '/videos/:id',
    },
    PODCAST_DETAIL_PAGE: {
        path: '/podcast/:id',
    },
    ARTICLE_DETAIL_PAGE: {
        path: '/article/:id',
    },
    MERCH_DETAIL_PAGE: {
        path: '/merch/:id',
    },
    ALBUM_DETAIL_PAGE: { path: SINGLE_ALBUM_PATH },
    SOUNDTRACK_DETAIL_PAGE: { path: SINGLE_SOUNDTRACK_PATH },
    REPORT: {
        path: '/report',
    },
    COMMUNITY_ECOSYSTEM: {
        path: '/:communityId/partners',
    },
    COMMUNITY_MEMBERS: {
        path: '/:communityId/members',
    },

    TEST_COMPONENTS: {
        path: '/test-components',
    },

    // Auth Routes

    LOGIN: {
        path: `/${AuthRoutes.LOGIN}`,
    },
    SIGN_UP: {
        path: `/${AuthRoutes.SIGN_UP}`,
    },
    WALLET_AUTH: {
        path: `/${AuthRoutes.WALLET}`,
    },

    /* Marketing Pages */
    BRANDS: {
        path: '/brands',
    },
    AMBASSADOR: { path: '/ambassador' },
    PRICING: { path: '/pricing' },
    REWARDS: {
        path: '/rewards',
    },
};

export const ResourcePathMap: Record<TContentType, string> = {
    article: SINGLE_ARTICLE_PATH,
    video: SINGLE_VIDEO_PATH,
    podcast: SINGLE_PODCAST_PATH,
    collect: SINGLE_MERCH_PATH,
    Event: APP_ROUTES.EVENT_PAGE.path,
    album: SINGLE_ALBUM_PATH,
    soundtrack: SINGLE_SOUNDTRACK_PATH,
};

export default AppNavigation;
