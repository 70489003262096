import React, { FC } from 'react';
import { SVGCIconProps } from '../SVGCIcon';

const Safety: FC<SVGCIconProps> = (props) => {
    const { color = '#ACACAC', height = 24, width = 24, style } = props;
    return (
        <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_32607_11649)">
                <path d="M20.5031 37.4121C19.8577 37.6611 19.1427 37.6611 18.4974 37.4121V37.4121C13.4571 35.4358 9.12935 31.9867 6.07831 27.5145C3.02727 23.0423 1.39456 17.7545 1.39307 12.3407V4.17854C1.39307 3.43972 1.68656 2.73116 2.20898 2.20874C2.73141 1.68632 3.43996 1.39282 4.17878 1.39282H34.8216C35.5605 1.39282 36.269 1.68632 36.7914 2.20874C37.3139 2.73116 37.6074 3.43972 37.6074 4.17854V12.3128C37.6115 17.7314 35.9814 23.0253 32.93 27.503C29.8786 31.9807 25.5477 35.4341 20.5031 37.4121V37.4121Z" stroke="#000001" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M23.679 15.3214H15.3218C13.7833 15.3214 12.5361 16.5686 12.5361 18.1071V23.6786C12.5361 25.2171 13.7833 26.4643 15.3218 26.4643H23.679C25.2175 26.4643 26.4647 25.2171 26.4647 23.6786V18.1071C26.4647 16.5686 25.2175 15.3214 23.679 15.3214Z" stroke="#000001" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M23.6789 15.3213V12.5356C23.6789 11.9869 23.5708 11.4435 23.3608 10.9366C23.1509 10.4296 22.8431 9.96895 22.455 9.58093C22.067 9.19291 21.6064 8.88512 21.0994 8.67513C20.5925 8.46514 20.0491 8.35706 19.5003 8.35706C18.9516 8.35706 18.4082 8.46514 17.9013 8.67513C17.3943 8.88512 16.9337 9.19291 16.5457 9.58093C16.1576 9.96895 15.8498 10.4296 15.6399 10.9366C15.4299 11.4435 15.3218 11.9869 15.3218 12.5356V15.3213" stroke="#000001" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_32607_11649">
                    <rect width="39" height="39" fill="white" />
                </clipPath>
            </defs>
        </svg>

    );
};

export default Safety;
