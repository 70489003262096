import React, { FC } from 'react';
import { SVGCIconProps } from '../SVGCIcon';

const Onboarding: FC<SVGCIconProps> = (props) => {
    const { color = '#ACACAC', height = 24, width = 24, style } = props;
    return (
        <svg width="33" height="42" viewBox="0 0 33 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.75 4.90002H28.1C28.8691 4.90002 29.6068 5.20556 30.1506 5.74941C30.6945 6.29327 31 7.0309 31 7.80002V36.8C31 37.5692 30.6945 38.3068 30.1506 38.8506C29.6068 39.3945 28.8691 39.7 28.1 39.7H4.9C4.13087 39.7 3.39325 39.3945 2.84939 38.8506C2.30553 38.3068 2 37.5692 2 36.8V7.80002C2 7.0309 2.30553 6.29327 2.84939 5.74941C3.39325 5.20556 4.13087 4.90002 4.9 4.90002H9.25" stroke="#000001" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M20.85 2H12.15C10.5484 2 9.25 3.29837 9.25 4.9V6.35C9.25 7.95163 10.5484 9.25 12.15 9.25H20.85C22.4516 9.25 23.75 7.95163 23.75 6.35V4.9C23.75 3.29837 22.4516 2 20.85 2Z" stroke="#000001" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9.25 25.2L15.05 29.55L22.3 17.95" stroke="#000001" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    );
};

export default Onboarding;
